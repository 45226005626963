<template>
  <div class="card full-height bg-ligth">
    <div class="card-header">
      <h3 class="mb-0">Usuarios</h3>
    </div>
    <div class="card-body">
      <div class="row d-flex align-items-end">
        <div class="form-group col-md-4">
          <label>Tipo</label>
          <select v-model="pesquisa.tipo" class="form-control">
            <option value="rg">rg</option>
            <option selected value="nome">Nome</option>
          </select>
        </div>
        <div class="form-group col-md-7">
          <label for="text">Campo</label>
          <input v-model="pesquisa.texto" type="text" class="form-control" id="text" placeholder />
        </div>
        <div class="form-group">
          <button class="btn btn-success" @click="pesquisar()">
            <i class="fa fa-search search-icon m-0 p-0"></i>
          </button>
          <!-- <button class="btn btn-warning" @click="imprimir()">
            <i class="fa fa-print print-icon m-0 p-0"></i>
          </button> -->
        </div>
      </div>
      <b-table class="
          table
          table-head-custom
          table-vertical-center
          table-head-bg
          table-borderless
        " show-empty empty-text="Nenhum registro encontrado!" :fields="[
            'id',
            'nome_sobrenome',
            'cpf',
            'rg',
            'status',
            'email',
            'actions',
          ]" :items="lista_usuarios" :per-page="perPage" :current-page="currentPage" id="funcionarios-table">
        <template #cell(status)="{ item }">
          <div class="text-right w-100 text-center">
            <span class="label label-lg label-inline" :class="{
                'label-light-primary': item.status === 1,
                'label-light-warning': item.status === 2,
              }">
              {{ item.status === 1 ? "Ativo" : "Desativado" }}
            </span>
          </div>
        </template>
        <template #cell(actions)="{ item }">
          <!-- <div class="dropdown show">
            <a
              class="btn btn-sm btn-hover-light-primary"
              data-toggle="dropdown"
            >
              <i class="ki ki-bold-more-ver p-0"></i>
            </a>

            <div class="dropdown-menu">
              <a class="dropdown-item" @click="atualizar(item)">Editar</a>

              <a
                v-if="lista_permissoes_filial.r_Permission"
                class="dropdown-item"
                href="#"
                @click.prevent="showModalPermission(item)"
                v-show="lista_permissoes_filial.r_Permission"
              >
                Permissões
              </a>
             <a
                class="dropdown-item"
                @click="financeiro('atendimento', item.id)"
                >Atendimentos</a
              > 
              <a
                class="dropdown-item"
                v-show="lista_permissoes_filial.lock_Usuario"
                @click="confirm('excluir', item)"
                >Exluir</a
              >
             <a v-if="item.perfil_id == 2"
                class="dropdown-item"
                href="#"
                @click.prevent="showModalPermission(item)"
                v-show="lista_permissoes_filial.r_Permission"
              >
                Permissões
              </a> 
            </div> 
          </div> -->
          <b-dropdown size="sm" variant="link"
            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon" no-caret right no-flip>
            <template v-slot:button-content>
              <i class="ki ki-bold-more-ver"></i>
            </template>
            <!--begin::Navigation-->
            <div class="navi navi-hover min-w-md-230px">
              <b-dropdown-text tag="div" class="navi-item">
                <a class="dropdown-item" @click="atualizar(item)">Editar</a>
              </b-dropdown-text>
              <b-dropdown-text tag="div" class="navi-item">
                <a v-if="lista_permissoes_filial.r_Permission" class="dropdown-item" href="#"
                  @click.prevent="showModalPermission(item)" v-show="lista_permissoes_filial.r_Permission">
                  Permissões
                </a>
              </b-dropdown-text>

              <b-dropdown-text tag="div" class="navi-item">
                <a class="dropdown-item" v-show="lista_permissoes_filial.lock_Usuario"
                  @click="confirm('excluir', item)">Exluir</a>
              </b-dropdown-text>
            </div>
            <!--end::Navigation-->
          </b-dropdown>
        </template>
      </b-table>
      <b-pagination v-model="currentPage" :total-rows="lista_usuarios.length" :per-page="perPage"
        aria-controls="funcionarios-table">
      </b-pagination>
    </div>
    <b-modal size="lg" id="modal-roles" title="Vincular Funções" centered ok-only :ok-disabled="disableSaveModal"
      ok-title="Salvar" @ok="submitPermissions">
      <div class="form-group row">
        <div class="col-md-12">
          <label class="col-md-12">Funções:*</label>
          <treeselect multiple :options="lista_roles" placeholder="Selecione as funções..." v-model="roleForm.roles" />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import { fireAlert } from "@/core/mixins/alertMessageMixin";

export default {
  mixins: [fireAlert],
  data() {
    return {
      selectedUser: null,
      disableSaveModal: false,
      roleForm: {
        user_id: null,
        roles: [],
      },
      perfil_id: 2,
      pesquisa: {
        tipo: "nome",
        texto: "",
        filial_id: null,
        perfil_id: 2
      },
      currentPage: 1,
      perPage: 6,
      value: "",
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Funcionarios" }]);
  },
  created() {
    this.listar_usuarios_matriculas();
    this.listar_perfil();
  },
  watch: {
    perfil_id() {
      console.log(this.perfil_id);
      this.buscarUsers();
    },
    'pesquisa.texto'(value) {
      this.pesquisar()
    },
  },
  computed: {
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
    lista_perfil() {
      return this.$store.state.configEmpresa.lista_perfil;
    },
    lista_roles() {
      return this.$store.state.permissoes.lista_roles.map((role) => ({
        id: role.id,
        label: role.name,
      }));
    },
    lista_usuarios() {
      return this.$store.state.configUsuarios.lista_usuarios_matriculas
      
    },

    mensagem_alert() {
      return this.$store.state.configEmpresa.mensagem_alert.msg;
    },
  },
  methods: {
    async confirm(tipo, item) {
      console.log(item);
      await this.$confirm({
        title: "Estas seguro?",
        message: `Vas a ` + tipo + ` un usuario definitivamente del sistema`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo == "excluir") this.excluir(item.id);
          }
        },
      });
    },
    async submitPermissions(e) {
      e.preventDefault();
      this.disableSaveModal = true;
      this.$store
        .dispatch("permissoes/vincular_regra_usuario", this.roleForm)
        .then(() => {
          this.roleForm.roles = [];
          this.$bvModal.hide("modal-roles");
          this.fireAlert({ title: "Atualizado com sucesso" });
        })
        .catch(() => this.fireAlert(this.mensagem_alert))
        .finally(() => (this.disableSaveModal = false));
    },

    async showModalPermission(item) {
      console.log(item);
      this.roleForm.user_id = item.user_id;
      console.log(this.roleForm)
      this.$bvModal.show("modal-roles");
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store.dispatch("permissoes/read_user_roles", item.user_id);
      this.roleForm.roles = this.$store.state.permissoes.user_roles.reduce(
        (prev, curr) => [...prev, curr.id],
        []
      );
      
      this.$store.dispatch("configEmpresa/MudarPreloader", false);
    },
    async buscarUsers() {
      //  this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store.dispatch(
        "configUsuarios/listar_usuarios_perfil",
        this.pesquisa
      );
      //  this.$store.dispatch("configEmpresa/MudarPreloader", false);
    },
    async listar_usuarios_matriculas() {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store.dispatch("configUsuarios/listar_usuarios_perfil",this.pesquisa);
      await this.$store.dispatch("permissoes/all_roles");
      this.$store.dispatch("configEmpresa/MudarPreloader", false);
      this.$store.dispatch("configEmpresa/atualizar", "");
    },
    async imprimir() {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store.dispatch("configFuncionarios/gerar_excel_funcionarios");
      this.$store.dispatch("configEmpresa/MudarPreloader", false);
    },
    atualizar(value) {
      this.$router.push({ name: "createFuncionario" });
      this.$store.dispatch("configEmpresa/atualizar", value);
    },
    async pesquisar() {
      console.log(this.pesquisa.tipo)
      var p = { filial_id:null,texto: this.pesquisa.texto,status: 2, tipo_pesquisa: this.pesquisa.tipo };
      await this.$store.dispatch("configUsuarios/pesquisar_usuarios", p);
      // this.$store.dispatch("configEmpresa/MudarPreloader", true);
      // await this.$store.dispatch(
      //   "configUsuarios/pesquisar_usuarios",
      //   this.pesquisa
      // );
      // this.$store.dispatch("configEmpresa/MudarPreloader", false);
    },
    async trocar_pagina(name, id) {
      await this.$store.dispatch("configEmpresa/guardarDados", id);
      this.$router.push({ name: name });
    },

    financeiro(name, id) {
      this.$router.push({ name: name, params: { usuario_id: id } });
    },

    async listar_perfil() {
      await this.$store.dispatch("configEmpresa/listar_perfil");
    },
    async excluir(id) {
      await this.$store.dispatch("configUsuarios/block_usuario", id);
      await this.$store.dispatch("configUsuarios/listar_usuarios_perfil",2);
      this.fireAlert(this.mensagem_alert);
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>